ion-popover {
  &.waypoint-popover::part(content) {
    margin-top: 34px;
    margin-left: -10px;
  }

  &.non-blocking {
    position: initial;
  }

  &.slim-popover::part(content) {
    width: initial;
  }
}

.popover-ios::part(content) {
  min-width: 200px;
  width: auto;
}
