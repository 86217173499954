noscript {
  color: var(--ion-color-primary);
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 1.6em;

  .flex-wrapper-outer {
    background-color: var(--ion-color-light);
    padding-top: 20vh;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: 100%;

    .flex-wrapper-inner {
      position: relative;
    }
  }

  p, .app-claim, .app-title {
    margin-left: 1.6rem;
  }

  .app-claim {
    margin-bottom: 3.2rem;
  }

  @media (max-width: 1023px) {
    .app-logo {
      margin-right: 0;
      margin-top: -2.75em;

      img {
        width: 12.5rem;
      }
    }

    .app-title {
      font-size: 4rem;
      font-weight: 700;
    }

    .app-claim {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  @media (min-width: 1024px) {
    .app-title {
      font-size: 5rem;
      font-weight: 700;
    }

    .app-claim {
      font-size: 2.4rem;
      font-weight: 500;
    }

    .app-logo {
      position: absolute;
      display: block;
      margin-right: -1rem;
      margin-top: -4.5rem;
      height: 15rem;
      width: 15rem;
    }
  }
}
