.ios {
  custom-tabs .tabbar .custom-tab-button.active {
    border-bottom: none;
  }

  .fixed-content, .scroll-content {
    // background-color: #f4f4f4;
  }

  .tabbar {
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #f4f4f4;
  }

  ion-action-sheet.poi-preview {
    .action-sheet-title {
      border-bottom: none;
      color: #333;
      font-size: 1.6rem;
      font-weight: 500;
      padding: 17px 16px 11px;
      text-align: left;
    }
    .action-sheet-sub-title {
      color: #333;
      padding: 0 16px;
      text-align: left;
    }
  }

  ion-menu {
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
  }

  ion-popover .list {
    margin: -1px 0 -1px;
  }

  .header .toolbar {
    .toolbar-background {
      background: var(--ion-color-primary)
    }

    .bar-button-clear, .bar-button-default, .toolbar-title {
      color: #fff;
    }
  }

  .item.no-bottom-border .item-inner {
    border-bottom: none;
  }
}
