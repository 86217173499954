.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  &.align-start {
    justify-content: flex-start;
  }

  &.align-end {
    justify-content: flex-end;
  }

  &.align-center {
    justify-content: center;
  }

  &.stretch {
    justify-content: stretch;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.match-parent {
    width: 100%;
  }
}

.flex-column {
  @extend .flex-row;
  flex-direction: column;

  &.match-parent {
    width: initial;
    height: 100%;
  }
}

.spacer {
  flex: 1;
}


