ion-button, .button-md, .button-ios {
  &.inline-link {
    transition: none 0s ease 0s;
    text-transform: none;
    font-weight: 400;
    color: var(--ion-color-tertiary);
    box-shadow: none;
    --background: transparent;
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    font-size: 16px;
    margin: 0;
    height: 22px;
    vertical-align: text-bottom;
    letter-spacing: 0;

    &:hover:not(disable-hover) {
      background-color: transparent;
      text-decoration: underline;
    }
  }
}
