@media only screen and (orientation: landscape) and (min-width: 1024px) {
  ion-tab-bar {
    background-color: var(--ion-color-primary-shade);
    height: 80px;
  }
  ion-tab-button {
    background-color: var(--ion-color-primary-shade);
  }
}

.md {
  ion-tab-bar[slot="top"] {
    border-bottom: none;
    box-shadow: 0 1px 3px rgb(0 0 0 / 50%);
  }

  ion-tab-button {
    border-bottom: 3px solid transparent;
    flex: 1;
    max-width: none;

    &.tab-selected {
      border-bottom: 3px solid #fff;
    }
  }

  /*
  ion-tab-bar[slot="top"]:after {
    left: 0;
    bottom: -5px;
    background-position: left 0 top -2px;
    position: absolute;
    width: 100%;
    height: 5px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==);
    background-repeat: repeat-x;
    content: "";
  }
  */
}
