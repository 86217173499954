/*
ion-fab {
  &.top {
    @extend ion-fab[top];
  }

  &.right {
    @extend ion-fab[right];
  }

  &.bottom {
    @extend ion-fab[bottom];
  }

  &.left {
    @extend ion-fab[left];
  }
}

.ios ion-fab {
  &.bottom, &[bottom] {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
*/
