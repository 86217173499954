.md {

  .alert-md .alert-message, .alert-md .alert-input-group {
    color: #333;
  }

  ion-action-sheet.poi-preview {
    //position: relative;
    .action-sheet-group {
      min-height: 120px;
    }
    .action-sheet-title {
      color: #333;
      font-weight: 500;
      padding: 11px 16px;
    }
    .action-sheet-sub-title {
      color: #333;
      padding: 0 16px;
    }
    .action-sheet-button {
      position: absolute;
      width: initial;
      right: 0;
      .button-inner {
        font-size: 1.4rem;
        font-weight: 500;
        justify-content: flex-end;
        text-transform: uppercase;
      }
      &.button-routing {
        color: #424242;
        bottom: 0;
        right: 90px;
        width: 120px;

        &.single-button {
          right: 0;
        }
      }
      &.button-details {
        color: var(--ion-color-secondary);
        bottom: 0;
        right: 0;
        width: 90px;
      }
    }
  }

  ion-navbar {
    .toolbar-title,
    .bar-button-default {
      color: #fff;
    }

    .bar-button-default-md,
    .bar-button-clear-md-default,
    .bar-button-md-default {
      &:hover:not(.disable-hover) {
        color: #fff;
      }
    }
  }

  ion-popover .list {
    margin: 0;
  }

  .card-md h2 {
    margin: 2px 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--ion-color-primary)
  }

  .header.no-shadow:after {
    background-image: none;
  }

  .tabs {
    .tab-button {
      border-bottom: 2px solid transparent;
      &[aria-selected=true] {
        border-bottom: 2px solid white;
      }
    }
    .tab-button-text {
      font-weight: 500;
    }
  }

  .toolbar {
    .toolbar-background {
      background: var(--ion-color-primary)
    }
    &.desktop-dark {
      .tabbar, .toolbar-background {
        background: var(--ion-color-primary_dark)
      }
    }
  }

  .footer .toolbar .toolbar-background {
    background: #fff;
  }

  .bar-button ion-icon,
  [icon-only] ion-icon {
    font-size: 2.4rem;
  }
}
