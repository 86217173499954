/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 5, 2018 */

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto-regular-webfont.woff') format('woff2'),
  url('../assets/fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto-medium-webfont.woff') format('woff2'),
  url('../assets/fonts/roboto-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto-bold-webfont.woff') format('woff2'),
  url('../assets/fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto-italic-webfont.woff') format('woff2'),
  url('../assets/fonts/roboto-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'yanone_kaffeesatz';
  src: url('../assets/fonts/yanonekaffeesatz-light-webfont.woff2') format('woff2'),
  url('../assets/fonts/yanonekaffeesatz-light-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'yanone_kaffeesatz';
  src: url('../assets/fonts/yanonekaffeesatz-bold-webfont.woff2') format('woff2'),
  url('../assets/fonts/yanonekaffeesatz-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'yanone_kaffeesatz';
  src: url('../assets/fonts/yanonekaffeesatz-thin-webfont.woff2') format('woff2'),
  url('../assets/fonts/yanonekaffeesatz-thin-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'yanone_kaffeesatz';
  src: url('../assets/fonts/yanonekaffeesatz-regular-webfont.woff2') format('woff2'),
  url('../assets/fonts/yanonekaffeesatz-regular-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

.yanone {
  font-family: 'yanone_kaffeesatz';
  font-weight: normal;

  &.yanone-bold {
    font-weight: bold;
  }
}

[class^="glyph-"], [class*=" glyph-"], .glyph {
  speak: none;
  font-style: normal;
  font-weight: 700 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyph-A:before {
  content: "A";
}

.glyph-B:before {
  content: "B";
}

.glyph-AB {
  font-size: 1.5rem !important;

  &:before {
    content: "A/B";
  }
}

.md {
  [class^="glyph-"], [class*=" glyph-"], .glyph {
    font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  }
}

.ios {
  [class^="glyph-"], [class*=" glyph-"], .glyph {
    font-family: -apple-system, "Helvetica Neue", "Roboto", sans-serif !important;
  }
}

