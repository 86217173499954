.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  //height: 20px;
}

.mapboxgl-ctrl-group > button.half-button {
  height: 15px;

  &:before {
    display: block;
    content: " ";
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:disabled:before {
    opacity: 0.5;
  }

  &.scroll-up:before {
    background-image: url("data:image/svg+xml; utf-8, <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Chevron Up</title><path fill='none' stroke='currentColor' stroke-linecap='square' stroke-miterlimit='10' stroke-width='48' d='M112 328l144-144 144 144'/></svg>");
  }

  &.scroll-down:before {
    background-image: url("data:image/svg+xml; utf-8, <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Chevron Down</title><path fill='none' stroke='currentColor' stroke-linecap='square' stroke-miterlimit='10' stroke-width='48' d='M112 184l144 144 144-144'/></svg>");
  }
}

.mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-group > button.mapboxgl-ctrl-compass {
  cursor: pointer;
}

.mapboxgl-canvas-container.mapboxgl-interactive:active,
.mapboxgl-ctrl-group > button.mapboxgl-ctrl-compass:active {
  cursor: pointer;
}

.mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-nav-compass {
  cursor: default;
}

.mapboxgl-canvas-container.mapboxgl-interactive:active,
.mapboxgl-ctrl-nav-compass:active {
  cursor: default;
}

.mapboxgl-ctrl-group-layers {
  position: relative;
  overflow: visible;
}

.mapboxgl-ctrl-icon {
  // @extend .icomoon;
  font-size: 1.8em;

  &.triggered {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }

  &.mapboxgl-ctrl-icoMoonCompass:before {
    display: block;
    content: " ";
    background-image: url("data:image/svg+xml; utf-8, <svg xmlns=\"http://www.w3.org/2000/svg\" class=\"ionicon\" viewBox=\"0 0 512 512\"><title>Compass</title><circle cx=\"256\" cy=\"256\" r=\"24\"/><path d=\"M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm48 256l-160 64 64-160 160-64z\"/></svg>");
    background-size: 21px 21px;
    height: 21px;
    width: 21px;
    margin: 4px;
  }

  &.mapboxgl-ctrl-poi:before {
    display: block;
    content: " ";
    background-image: url("data:image/svg+xml; utf-8, <svg xmlns=\"http://www.w3.org/2000/svg\" class=\"ionicon\" viewBox=\"0 0 512 512\"><title>Location</title><path d=\"M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1164-64 64.07 64.07 0 01-64 64z\"/></svg>");
    background-size: 21px 21px;
    height: 21px;
    width: 21px;
    margin: 4px;
  }

  &.mapboxgl-ctrl-layers:before {
    display: block;
    content: " ";
    background-image: url("data:image/svg+xml; utf-8, <svg xmlns=\"http://www.w3.org/2000/svg\" class=\"ionicon\" viewBox=\"0 0 512 512\"><title>Layers</title><path d=\"M480 150L256 48 32 150l224 104 224-104zM255.71 392.95l-144.81-66.2L32 362l224 102 224-102-78.69-35.3-145.6 66.25z\"/><path d=\"M480 256l-75.53-33.53L256.1 290.6l-148.77-68.17L32 256l224 102 224-102z\"/></svg>");
    background-size: 21px 21px;
    height: 21px;
    width: 21px;
    margin: 4px;
  }

  &.mapboxgl-ctrl-node-network {
    &:before {
      display: block;
      content: " ";
      background-image: url("data:image/svg+xml; utf-8, <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"1024\" height=\"1024\" viewBox=\"0 0 1024 1024\"><title></title><path d=\"M511.988 139.379c-205.543 0-372.607 167.066-372.607 372.609s167.064 372.639 372.607 372.639c205.543 0 372.639-167.095 372.639-372.639s-167.095-372.609-372.639-372.609zM428.857 320.057c29.117 0 58.223 3.757 58.223 21.551 0 10.245-1.071 26.42-5.924 42.596-12.402-4.314-32.341-7.531-44.203-7.531-45.293 0-69.036 29.653-72.271 113.229 15.098-19.411 32.365-28.574 63.639-28.574 43.137 0 73.316 29.11 73.316 104.598 0 96.517-36.651 137.488-101.355 137.488-86.811 0-92.75-66.324-92.75-177.939 0-159.603 50.152-205.416 121.326-205.416zM618.871 375.57c69.017 0 97.604 49.619 97.604 143.979 0 122.938-21.029 184.4-98.674 184.4-78.723 0-95.459-62.009-95.459-164.457 0-107.84 28.59-163.922 96.529-163.922zM622.115 428.434c-29.115 0.002-33.99 44.214-33.99 113.23 0 81.419 3.78 108.379 31.818 108.379 22.107 0 32.893-33.438 32.893-130.494 0-57.692-5.917-91.113-30.719-91.115h-0.002zM409.449 516.842c-15.637 0-29.132 7.026-44.23 25.359 0.539 2.696 0.564 4.839 0.564 6.996-2.696 88.968 19.948 100.846 37.742 100.846 24.803 0 38.816-34.502 38.816-71.707s-10.786-61.494-32.893-61.494z\" fill=\"%23bb1e10\"></path></svg>");
      background-size: 21px 21px;
      height: 21px;
      width: 21px;
      margin: 4px;
    }

    &.inactive:before {
      background-image: url("data:image/svg+xml; utf-8, <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"1024\" height=\"1024\" viewBox=\"0 0 1024 1024\"><title></title><path d=\"M511.988 139.379c-205.543 0-372.607 167.066-372.607 372.609s167.064 372.639 372.607 372.639c205.543 0 372.639-167.095 372.639-372.639s-167.095-372.609-372.639-372.609zM428.857 320.057c29.117 0 58.223 3.757 58.223 21.551 0 10.245-1.071 26.42-5.924 42.596-12.402-4.314-32.341-7.531-44.203-7.531-45.293 0-69.036 29.653-72.271 113.229 15.098-19.411 32.365-28.574 63.639-28.574 43.137 0 73.316 29.11 73.316 104.598 0 96.517-36.651 137.488-101.355 137.488-86.811 0-92.75-66.324-92.75-177.939 0-159.603 50.152-205.416 121.326-205.416zM618.871 375.57c69.017 0 97.604 49.619 97.604 143.979 0 122.938-21.029 184.4-98.674 184.4-78.723 0-95.459-62.009-95.459-164.457 0-107.84 28.59-163.922 96.529-163.922zM622.115 428.434c-29.115 0.002-33.99 44.214-33.99 113.23 0 81.419 3.78 108.379 31.818 108.379 22.107 0 32.893-33.438 32.893-130.494 0-57.692-5.917-91.113-30.719-91.115h-0.002zM409.449 516.842c-15.637 0-29.132 7.026-44.23 25.359 0.539 2.696 0.564 4.839 0.564 6.996-2.696 88.968 19.948 100.846 37.742 100.846 24.803 0 38.816-34.502 38.816-71.707s-10.786-61.494-32.893-61.494z\" fill=\"%23585858\"></path></svg>");
    }
  }

  &.mapboxgl-ctrl-node-network-lines {
    &:before {
      display: block;
      content: " ";
      background-image: url("data:image/svg+xml; utf-8, <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"308\" height=\"308\" viewBox=\"-42 -42 308 308\"><title></title><path d=\"M 9.3863346,213.42439 C -8.9156652,192.06338 -4.9595501,185.70342 86.190043,89.924796 178.1125,-6.665834 183.1265,-10.074508 201.58334,11.467433 219.88534,32.828446 215.93059,39.189854 124.781,134.96703 32.857181,231.55766 27.843174,234.96633 9.3863346,213.42439 Z\" fill=\"%23bb1e10\" /></svg>");
      background-size: 21px 21px;
      height: 21px;
      width: 21px;
      margin: 4px;
    }

    &.inactive:before {
      background-image: url("data:image/svg+xml; utf-8, <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"308\" height=\"308\" viewBox=\"-42 -42 308 308\"><title></title><path d=\"M 9.3863346,213.42439 C -8.9156652,192.06338 -4.9595501,185.70342 86.190043,89.924796 178.1125,-6.665834 183.1265,-10.074508 201.58334,11.467433 219.88534,32.828446 215.93059,39.189854 124.781,134.96703 32.857181,231.55766 27.843174,234.96633 9.3863346,213.42439 Z\" fill=\"%23585858\" /></svg>");
      }
  }
}

.mapboxgl-ctrl-layerchooser {
  @extend .mapboxgl-ctrl-group;
  position: absolute;
  width: 200px;
  top: 0;
  right: 40px;
  transition: visibility .25s, opacity .25s;
  visibility: visible;
  opacity: 1;

  form {
    border: none;
  }

  fieldset {
    border: none;
    margin: 0 2px;
    padding: 0.5em;

    .layerchooser-title {
      color: #333;
      font-family: "Roboto", "Helvetica Neue", sans-serif;
      font-size: 1em;
      line-height: 1em;
      margin-bottom: 0.5em;
    }

    .layerchooser-checkbox-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: 0.75em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .layerchooser-legend {
      height: 5px;
      width: 20px;
      transform: rotate(-30deg);
      margin-left: 8px;
    }

    label {
      margin-left: 0.5em;
      color: #585858;
      font-family: "Roboto", "Helvetica Neue", sans-serif;
      font-size: 1.2em;
      line-height: 1.2em;
    }
  }

  &.invisible {
    visibility: hidden;
    opacity: 0;
  }
}

.mapboxgl-ctrl-poichooser {
  height: 210px;
  overflow: hidden;

  &.invisible {
    display: none;
  }

  > .category-wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;

    > button {
      width: 30px;
      height: 30px;
      display: block;
      padding: 0;
      outline: none;
      border: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background-color: transparent;
      cursor: pointer;
      background-size: 23px;
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      &.triggered {
        background-color: rgba(0, 0, 0, 0.1) !important;
      }

    }
  }
}

.mapboxgl-popup {

  .mapboxgl-popup-content {
    padding: 0;
  }

  &.poi-marker-popup {
    .mapboxgl-popup-content {
      height: 150px;
      width: 250px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;

      .poi-marker-popup-header {
        padding: 10px;

        .poi-marker-popup-title {
          color: #333;
          font-size: 16px;
          font-weight: 500;
        }

        .poi-marker-popup-subtitle {
          color: #585858;
          font-size: 14px;
          font-weight: 400;
          margin-top: .2em;
        }
      }
    }
  }

  &.routing-marker-popup {
    .mapboxgl-popup-content {
      display: flex;
      flex-direction: column;
    }

    &.lines-1 {
      height: 44px;
    }

    &.lines-2 {
      height: 88px;
    }

    &.lines-3 {
      height: 132px;
    }
  }
}

.marker-poi-wrapper {
  cursor: pointer;

  .marker-poi {
    position: relative;
    background-color: var(--ion-color-primary);
    border-radius: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    width: 30px;
    height: 30px;

    .marker-poi-content {
      position: absolute;
      width: 30px;
      height: 30px;
      font-family: icomoon;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      color: var(--ion-color-light);

      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        padding: 0 0 2px 0 !important;
      }
    }
  }
}

.marker-hover-wrapper {
  cursor: pointer;

  .marker-hover {
    position: relative;
    background-color: #008ac4;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    width: 13px;
    height: 13px;
  }

}

.marker-invisible-wrapper {
  cursor: pointer;

  .marker-invisible {
    position: relative;
    background-color: #008ac4;
    border-radius: 5px;
    width: 5px;
    height: 5px;
    opacity: 0.0;
  }

}

.ios {
  app-map {
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
  }

  .mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mapboxgl-popup {
    font-family: -apple-system, "Helvetica Neue", Roboto, sans-serif;
  }
}

.md .mapboxgl-popup {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}
