ion-list {
  &.no-margin-bottom {
    margin-bottom: 0;
  }
  &.plain {
    &.list-ios {
      > .item-block:first-child {
        border-top: none;
      }
      > .item-block:last-child, > .item-wrapper:last-child .item-block {
        border-bottom: none;

        .item-inner {
          border-bottom: 0.55px solid #c8c7cc;
        }
      }
    }
    &.list-md {
      .item-input:last-child {
        border-bottom: none;
      }
      > .item-block:last-child .item-inner {
        border-bottom: 1px solid #dedede;
      }
    }
  }

  .item > ion-icon[large] {
    min-width: 3.2rem;
  }
}
