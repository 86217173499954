@media only screen and (orientation: portrait) {
  .landscape-header {
    display: none;
  }

  .desktop-only {
    display: none !important;
  }

  .md {
    ion-nav > .ion-page > ion-content {
      > .fixed-content, > .scroll-content {
        margin-top: 56px !important;
      }
    }
  }
}


