.parallax-wrapper {
  perspective: 1px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  perspective-origin-x: 100%;

  .parallax-item {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform-origin-x: 100%;

    &.parallax-item-background {
      transform: translateZ(-1px) scale(2);
    }

    &.parallax-item-foreground {
      transform: translateZ(0);
    }
  }
}
